@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
  font-family:'daxlinepro-extrabold.ttf';
  src: url(Fonts/daxlinepro-extrabold.ttf);
}

@font-face{
  font-family:'daxlinepro-black.ttf';
  src: url(Fonts/daxlinepro-black.ttf);
}

@font-face{
  font-family:'daxlinepro-Bold.ttf';
  src: url(Fonts/DaxlinePro-Bold.ttf);
}

@font-face{
  font-family:'daxlinepro-light.ttf';
  src: url(Fonts/daxlinepro-light.ttf);
}

@font-face{
  font-family:'daxlinePro-Regular.ttf';
  src: url(Fonts/DaxlinePro-Regular.ttf);
}

@font-face{
  font-family:'daxlinePro-Medium.ttf';
  src: url(Fonts/DaxlinePro-Medium.ttf);
}

@font-face{
  font-family:'daxlinepro-thin.ttf';
  src: url(Fonts/daxlinepro-thin.ttf);
}


*{
  margin: 0;
  padding: 0;
}

#textsection{
  font-family:'daxlinepro-Bold.ttf';  
  font-size: 32px;
  line-height: 43.58px ;
  text-align: left;
}

#buttonsection{
font-size: 16px;
line-height: 21px;
font-family: 'daxlinePro-Medium.ttf';
}

#aboutussection,#featurehsection,#featurespsection,
#otherfeaturestext,#testimonalhsection,#articesessiontext{
  font-family:'daxlinepro-Bold.ttf'; 
}

#aboutparagraph{
  font-family:'daxlinepro-regular.ttf'; 
  line-height: 37.65px;
}

#featurestext{
  font-family:'daxlinepro-light.ttf';
  line-height: 32px;
  font-size: 15px;
}

#featuresparagraph{
line-height: 34px;
font-family:'daxlinepro-light.ttf'; 
}

#testimonalpsection{
  font-family:'daxlinepro-regular.ttf'; 
}

#articlebutton{
  font-family: 'daxlinePro-Medium.ttf';
}

#articletext{
  font-family: 'daxlinepro-Bold.ttf';
}

#articletextp{
  font-family:'daxlinepro-regular.ttf'; 
}

#footersessioninfop{
  font-family:'daxlinepro-regular.ttf'; 
}

#footersessionh{
  font-family: 'daxlinepro-Bold.ttf';
}












