@media screen and (max-width:850px){
#otherfeaturesresponsive{
margin-left: 100px;
}





}

