
*{
  margin: 0px;
  padding: 0;
  box-sizing: border-box; 
  font-family: 'daxlinepro-light';
  } 
  
  body{
  overflow-x: hidden;
  font-family: 'daxlinepro-light';
  }
  

  header{
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    background-color: white;
    
    }
    
    header .navigation a{
    color: #535252;
    text-decoration: none;
    font-weight: 500; 
    letter-spacing: 1px;
    font-size:18px;
    font-family:'DaxlinePro-Bold.ttf';  
    }
    
    header .navigation a:not(:last-child){
    margin-right: 40px;
    } 
    
    .navigation{
    width: 50%;
    }
    
    
     header .navigation a:hover{
    color: orange;
    } 
    
    .logo{
    width: 233.75px;
    height: 85px;
    margin-left: 3%;
    }
    
    .navbutton{
    height: 40px;
    width: 30%;
    left: 0px;
    top: 0px;
    color: #535252;
    background-color: #97F300;
    font-style: normal;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    line-height:22px ;
    font-family:'DaxlinePro-Regular.ttf';  
    /* margin-left: 30%; */
    margin-left: 2%;
    }
    
    .navbutton:hover{
    color: #ffffff;
    background-color: #054828;
    } 
    
    .hamburger{
    display: none;
    }
    
    .check{
    display: none;
    }
    


    @media screen and (max-width:850px){
      .hamburger {
      display: flex;
      margin-left: 95% ;
      margin-top: -60px;
      }
      .check {
      display: none;
      }
     
      .hamburger i{
      font-size: 2rem;
      color:black;
      transform: .3s;
      
      }
      .navigation{
      position:fixed;
      top: -30px;
      right: 0;
      bottom: 0;
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      color:#ffffff;
      background-color: white;
      padding: 0.2rem ;
      transform: translateX(100%);
      z-index: 11;
      transition: all .5s;
      margin-top: 4.5rem;
      
      }
      
      .navigation a{
      margin: 0.5rem;
      border-bottom:1px solid #51DD11;
      padding-bottom:0.5rem ;
      }

      .navigation a{
      color: var(--white);
      transition: all .3s;
      
      }
      .navigation a:hover{
      margin: 1rem;
      background-color: transparent;
      color:#e5e2f9;
      
      
      }
      
      .navbutton{
      /* align-self: center; */
      margin: 0.5rem;
      }

     .navbutton{
      width: 70%;
      height: 60px;
      font-size: 15px;
      }
       

    
      .navigation a::before{
      display: none;
      }
      .navigation::after{
      display: none;
      }
      
      
      #toggle:checked ~ .navigation {
      transform: translate(0);
      }
      
      #toggle:checked ~ .hamburger i {
      border: 1px solid black;
      padding: .5rem;
      border-radius: 5px;
      transition: .5s;
      
      }
      .navigation {
      text-align: center;
      }

      a {
      text-align: center;
      margin-left: 1rem;
      font-size: .6rem;
      }
      
      .logo{
      width: 40%;
      position: relative;
      top: -20px;
      
      }
      
      }